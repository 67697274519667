import LoginModule from "../modules/auth/Login";
import RegisterModule from "../modules/auth/Register";

// import PricingPage from "views/Pages/PricingPage.jsx";
// import LoginPage from "views/Pages/LoginPage.jsx";
// import RegisterPage from "views/Pages/RegisterPage.jsx";
// import LockScreenPage from "views/Pages/LockScreenPage.jsx";

// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
// import Fingerprint from "@material-ui/icons/Fingerprint";
// import MonetizationOn from "@material-ui/icons/MonetizationOn";
// import LockOpen from "@material-ui/icons/LockOpen";

const pagesRoutes = [
  {
    path: "/login",
    name: "Login",
    icon: PersonAdd,
    component: LoginModule,
  },
  {
    path: "/register",
    name: "Register",
    icon: PersonAdd,
    component: RegisterModule,
  },
  { redirect: true, path: "/", pathTo: "/login", name: "Dashboard" },
];

export default pagesRoutes;
