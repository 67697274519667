import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

import QRCode from "qrcode.react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Contacts from "@material-ui/icons/Contacts";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import { GiShoppingCart } from "react-icons/gi";
import { FaQrcode } from "react-icons/fa";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import consts from "../../consts";

class RegularForms extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem(consts.UserKey));
    this.state = {
      user,
      showNotification: false,
      configNotification: {
        message: "Erro inesperado.",
        color: "danger",
      },
      step: 1,
      amount: "",
      amount_virtual: "",
      amount_physical: "",
      amount_gift: "",
      qrcode: "",
      code: "",
      finished: false,
    };
    // this.handleChange = this.handleChange.bind(this);
    // this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
  }

  showNotification(message, color) {
    this.setState({
      configNotification: {
        message,
        color,
      },
      showNotification: true,
    });
    setTimeout(
      function() {
        this.hideNotification();
      }.bind(this),
      6000
    );
  }

  hideNotification() {
    this.setState({
      showNotification: false,
    });
  }

  change(event, stateName, type, stateNameEqualTo) {
    this.setState({ [stateName]: event.target.value });
  }

  generateCode() {
    // console.log("generateCode");

    const qrcode = JSON.stringify({
      to: this.state.user.account,
      brasal: 0,
      amount_virtual: this.state.amount_virtual,
      amount_physical: this.state.amount_physical,
      amount_gift: this.state.amount_gift,
    });
    // console.log(qrcode);
    axios
      .post(`${consts.API_URL}/codes`, { value: qrcode })
      .then((resp) => {
        // console.log(resp);
        this.setState({ qrcode, step: 2, code: resp.data.code });
        // this.transfer(JSON.parse(resp.data.value));
        // this.showNotification("teste", "success");
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        const message =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Código inválido";
        this.showNotification(message, "danger");
      });
  }

  tryGenerateCode() {
    if (
      this.state.amount_physical > 0 ||
      this.state.amount_virtual > 0 ||
      this.state.amount_gift > 0
    ) {
      this.generateCode();
    } else {
      this.showNotification("Um dos valores precisa ser maior que zero.", "danger");
      return false;
    }
  }

  finish() {
    this.setState({
      finished: true,
    });
  }

  back() {
    this.setState({
      step: 1,
    });
  }

  renderForm() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <GiShoppingCart />
          </CardIcon>
          <h4 className={classes.cardIconTitle}> </h4>
        </CardHeader>
        <CardBody>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={8}>
                <FormLabel
                  style={{
                    display: "block",
                    color: "#666",
                  }}
                  className={classes.labelHorizontal}
                >
                  Insira a quantidade de <strong>Vasilhames Físicos</strong> que você vai recolher
                  do consumidor.
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  success={this.state.amount_physicalState === "success"}
                  error={this.state.codeState === "error"}
                  labelText={<span />}
                  id="amount_physical"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => this.change(event, "amount_physical", "length", 8),
                    value: this.state.amount_physical,
                  }}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={8}>
                <FormLabel
                  style={{
                    display: "block",
                    color: "#666",
                  }}
                  className={classes.labelHorizontal}
                >
                  Insira a quantidade de <strong>Vasilhames Virtuais</strong> que você vai recolher
                  do consumidor.
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  success={this.state.amount_virtualState === "success"}
                  error={this.state.codeState === "error"}
                  labelText={<span />}
                  id="amount_virtual"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => this.change(event, "amount_virtual", "length", 8),
                    value: this.state.amount_virtual,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={8}>
                <FormLabel
                  style={{
                    display: "block",
                    color: "#666",
                  }}
                  className={classes.labelHorizontal}
                >
                  Insira a quantidade de <strong>Brindes</strong> que você vai entregar para o
                  consumidor.
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  success={this.state.amount_giftState === "success"}
                  error={this.state.codeState === "error"}
                  labelText={<span />}
                  id="amount_gift"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => this.change(event, "amount_gift", "length", 8),
                    value: this.state.amount_gift,
                  }}
                />
              </GridItem>
            </GridContainer>
            <br />
            <br />
            <Button color="primary" onClick={() => this.tryGenerateCode()}>
              Gerar código
            </Button>
          </form>
        </CardBody>
      </Card>
    );
  }

  renderQrCode() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <FaQrcode />
          </CardIcon>
          <h4 className={classes.cardIconTitle}> </h4>
        </CardHeader>
        <CardBody>
          <p>Este é o QR Code para você receber as Embalagens Virtuais.</p>
          <p>Mostre-o para quem fará a transferência.</p>
          <br />
          <QRCode value={this.state.qrcode} renderAs="canvas" size={250} />
          <br />
          <br />
          {this.state.code}
          <br />
          <br />
          <Button onClick={() => this.back()}>Voltar</Button>
          <Button color="primary" onClick={() => this.finish()}>
            Concluído
          </Button>
        </CardBody>
      </Card>
    );
  }

  render() {
    if (this.state.finished) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <GridContainer justify="center">
        <Snackbar
          place="tr"
          color={this.state.configNotification.color}
          icon={AddAlert}
          message={this.state.configNotification.message}
          open={this.state.showNotification}
          closeNotification={() => this.hideNotification()}
          close
        />
        <GridItem xs={12} sm={12} md={this.state.step === 1 ? 8 : 6}>
          {this.state.step === 1 ? this.renderForm() : this.renderQrCode()}
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(regularFormsStyle)(RegularForms);
