import React from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink } from "react-router-dom";
import axios from "axios";
import consts from "../../consts";

import { login, recovery, showNotification, signup } from "./authActions";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import InstitutionIcon from "@material-ui/icons/Home";
import Check from "@material-ui/icons/Check";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
// import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

// console.log(loginPageStyle);
// console.log(registerPageStyle);
const styles = {
  // ...loginPageStyle,
  ...registerPageStyle,
  cardTitle: {
    color: "#fff",
    textAlign: "center",
  },
  // checkboxAndRadio: registerPageStyle.checkboxAndRadio,
  // checkedIcon: registerPageStyle.checkedIcon,
  // uncheckedIcon: registerPageStyle.uncheckedIcon,
  // checked: registerPageStyle.checked,
  // checkRoot: registerPageStyle.checkRoot,
  // label: registerPageStyle.label,
};

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      step1: true,
      accept_terms: false,
      owner: false,
      model: {
        cod: "",
        // username: "",
        // password: "",
      },
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  updateField(event) {
    console.log("updateField");
    console.log(event.target.name);
    console.log(event.target.value);
    const model = { ...this.state.model };
    let value = event.target.value;
    if (event.target.name == "cod") {
      value = value.replace(/[^0-9.]/g, "");
      // console.log("value only numver: ", value);
      value = value.length > 0 ? parseInt(value).toString() : "0";
      // .substring(value.length - 10, value.length);
      // console.log("value with out zeros: ", value);
      // while (value.length < 10) {
      //   value = `0${value}`;
      // }
    }
    console.log("value: ", value);
    model[event.target.name] = value;
    this.setState({ model });
  }

  consultCodClient() {
    console.log("consultCodClient");
    console.log(this.state);
    axios
      .post(`${consts.OAPI_URL}/pdv/consult-cod-client`, this.state.model)
      .then((resp) => {
        console.log(resp);
        this.setState({
          step1: false,
          model: {
            ...this.state.model,
            sap: resp.data,
          },
        });
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errors && e.response.data.errors > 0) {
          e.response.data.errors.forEach((error) => {
            // toastr.error('Erro', error))
            const configNotification = {
              message: error,
              color: "danger",
            };
            this.props.showNotification(configNotification);
          });
        } else if (e.response && e.response.data && e.response.data.message) {
          const configNotification = {
            message: e.response.data.message,
            color: "danger",
          };
          this.props.showNotification(configNotification);
        } else {
          // toastr.error('Erro', 'Erro inesperado.')
          const configNotification = {
            message: "Erro inesperado.",
            color: "danger",
          };
          this.props.showNotification(configNotification);
        }
      });
  }

  // signup() {
  //   console.log("signup");
  //   console.log(this.state);
  //   axios
  //     .post(`${consts.OAPI_URL}/pdv/signup`, this.state.model)
  //     .then((resp) => {
  //       console.log(resp);
  //       this.setState({
  //         step1: false,
  //         model: {
  //           ...this.state.model,
  //           sap: resp.data,
  //         },
  //       });
  //     })
  //     .catch((e) => {
  //       if (e.response && e.response.data && e.response.data.errors && e.response.data.errors > 0) {
  //         e.response.data.errors.forEach((error) => {
  //           // toastr.error('Erro', error))
  //           const configNotification = {
  //             message: error,
  //             color: "danger",
  //           };
  //           this.props.showNotification(configNotification);
  //         });
  //       } else if (e.response && e.response.data && e.response.data.message) {
  //         const configNotification = {
  //           message: e.response.data.message,
  //           color: "danger",
  //         };
  //         this.props.showNotification(configNotification);
  //       } else {
  //         // toastr.error('Erro', 'Erro inesperado.')
  //         const configNotification = {
  //           message: "Erro inesperado.",
  //           color: "danger",
  //         };
  //         this.props.showNotification(configNotification);
  //       }
  //     });
  // }

  onSubmit() {
    this.state.step1 ? this.consultCodClient() : this.props.signup(this.state.model);
  }

  // changeForm() {
  //   this.setState({ step1: !this.state.step1 });
  // }

  // renderForm() {
  //   const { classes } = this.props;
  //   return (
  //     <div>
  //       <CustomInput
  //         labelText="Email"
  //         id="email"
  //         formControlProps={{
  //           fullWidth: true,
  //         }}
  //         inputProps={{
  //           endAdornment: (
  //             <InputAdornment position="end">
  //               <Email className={classes.inputAdornmentIcon} />
  //             </InputAdornment>
  //           ),
  //           name: "username",
  //           onChange: (e) => this.updateField(e),
  //         }}
  //       />
  //       <CustomInput
  //         labelText="Senha"
  //         id="password"
  //         formControlProps={{
  //           fullWidth: true,
  //         }}
  //         inputProps={{
  //           endAdornment: (
  //             <InputAdornment position="end">
  //               <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
  //             </InputAdornment>
  //           ),
  //           type: "password",
  //           name: "password",
  //           onChange: (e) => this.updateField(e),
  //         }}
  //       />
  //     </div>
  //   );
  // }

  renderStep1() {
    const { classes } = this.props;
    return (
      <div>
        <CustomInput
          labelText="Código do cliente Brasal"
          id="cod"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: "cod",
            value: this.state.model.cod,
            onChange: (e) => this.updateField(e),
          }}
        />
      </div>
    );
  }

  renderStep2() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            labelText="Código do cliente Brasal"
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              readOnly: true,
              name: "cod",
              value: this.state.model.cod,
              onChange: (e) => this.updateField(e),
            }}
          />
          <CustomInput
            labelText="Nome Fantasia"
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "cod",
              readOnly: true,
              value: this.state.model.sap.Fantasia,
              onChange: (e) => this.updateField(e),
            }}
          />
          <CustomInput
            labelText="Nome Completo"
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "name",
              value: this.state.model.name,
              onChange: (e) => this.updateField(e),
            }}
          />
          <CustomInput
            labelText="Email"
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "email",
              value: this.state.model.email,
              onChange: (e) => this.updateField(e),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            labelText="CPF"
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "cpf",
              value: this.state.model.cpf,
              onChange: (e) => this.updateField(e),
            }}
          />
          <CustomInput
            labelText="Telefone"
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "tel",
              value: this.state.model.tel,
              onChange: (e) => this.updateField(e),
            }}
          />
          <CustomInput
            labelText="Senha"
            id="password"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "password",
              name: "password",
              value: this.state.model.password,
              onChange: (e) => this.updateField(e),
            }}
          />
          <CustomInput
            labelText="Confirmar Senha"
            id="password"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "password",
              name: "confirm_password",
              value: this.state.model.confirm_password,
              onChange: (e) => this.updateField(e),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={12}>
          <div className={classes.checkboxAndRadio}>
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={() =>
                    this.setState({
                      accept_terms: !this.state.accept_terms,
                    })
                  }
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label="Aceito os Termos de uso e Termo de política de Privacidade"
            />
          </div>
          <div className={classes.checkboxAndRadio}>
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={() =>
                    this.setState({
                      owner: !this.state.owner,
                    })
                  }
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label="Confirmo, sob as penas da Lei, que sou o responsável legal por esse estabelecimento comercial."
            />
          </div>
        </GridItem>
      </GridContainer>
    );
  }

  render() {
    const { classes, handleSubmit } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={this.state.step1 ? 4 : 8}>
            <form
              onSubmit={handleSubmit((v) => {
                this.onSubmit();
                // this.setState({ step1: true });
              })}
            >
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="primary"
                >
                  <h4 className={classes.cardTitle}>Cadastro</h4>
                </CardHeader>
                <CardBody>
                  {this.state.step1 ? this.renderStep1() : this.renderStep2()}
                  <span>
                    <NavLink to={"/login"}>Já sou cadastrado. Desejo logar-me</NavLink>
                  </span>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  {this.state.step1 ? (
                    <Button type="submit" color="primary" simple size="lg" block>
                      Consultar
                    </Button>
                  ) : this.state.accept_terms && this.state.owner ? (
                    <Button type="submit" color="primary" simple size="lg" block>
                      Cadastrar
                    </Button>
                  ) : null}
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

LoginPage = reduxForm({ form: "authForm" })(LoginPage);
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login,
      recovery,
      signup,
      showNotification,
    },
    dispatch
  );
const render = withStyles(styles)(LoginPage);
export default connect(
  null,
  mapDispatchToProps
)(render);
