import React from "react";
import axios from "axios";
import consts from "../../consts";
// react plugin for creating charts
import ChartistGraph from "react-chartist";

import "../../assets/jss/carousel.css";
// import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
// import Carousel from "./carousel";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Icon from "@material-ui/core/Icon";

import Warning from "@material-ui/icons/Warning";
import Store from "@material-ui/icons/Store";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import People from "@material-ui/icons/People";
import Map from "@material-ui/icons/Map";
import PinDrop from "@material-ui/icons/PinDrop";
import SentimentSatisfied from "@material-ui/icons/SentimentSatisfied";

import { FaBoxes } from "react-icons/fa";
// core components
import Heading from "components/Heading/Heading.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import Danger from "components/Typography/Danger.jsx";

import {
  totalRegistersByDate,
  usuariosQMaisTwitam,
  localizacao,
  sentimentos,
  roundedLineChart,
  straightLinesChart,
  simpleBarChart,
  colouredLineChart,
  multipleBarsChart,
  colouredLinesChart,
  pieChart,
} from "./charts0.jsx";

import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";

class Charts extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem(consts.UserKey));
    // console.log(user);
    this.state = {
      user,
      account_balance_physical: 0,
      account_balance_virtuals: 0,
      account_balance_gifts: 0,
      points: "",
      account: "",
      banners: [],
      // totalCustomers: 0,
      // totalPdvs: 0,
      // totalPdvsAtivos: 0,
      // totalUsersTransfers: { labels: [], series: [] },
      // totalRegistersByDate: { labels: [], series: [] },
      // totalPdvsTransfers: { labels: [], series: [] },

      // totalUsersTalkingAbout: 0,
      // locations: { labels: [], series: [] },
    };
  }

  componentWillMount() {
    this.getAll();
  }

  getAll() {
    this.get();
    this.getBanners();
  }

  get() {
    // .get(`${Constants.API}/accounts/${this.state.user.account_id}`)
    axios.get(`${consts.API_URL}/accounts/${this.state.user.account_id}`).then((response) => {
      // console.log(resp.data);

      // const totalCustomers = resp.data;
      this.setState({
        account_balance_physical: response.data.balance_physical,
        account_balance_virtuals: response.data.balance_virtuals,
        account_balance_gifts: response.data.balance_gifts,
        points: response.data.points,
        account: response.data,
      });
    });
  }

  getBanners() {
    axios.get(`${consts.API_URL}/banners-pdv-desktop`).then((resp) => {
      const banners = resp.data.result;
      // console.log(banners);
      this.setState({ banners });
    });
  }

  // getTotalPdvsAtivos() {
  //   axios.get(`${consts.API_URL}/dashboard/total-pdvs-ativos`).then((resp) => {
  //     const totalPdvsAtivos = resp.data;
  //     this.setState({ totalPdvsAtivos });
  //   });
  // }

  // getTotalRegistersByDate() {
  //   axios.get(`${consts.API_URL}/dashboard/total-registers-by-date`).then((resp) => {
  //     const labels = resp.data.map((data) => {
  //       return data.date;
  //     });
  //     const series = [
  //       resp.data.map((data) => {
  //         return data.total;
  //       }),
  //     ];
  //     const totalRegistersByDate = { labels, series };
  //     this.setState({ totalRegistersByDate });
  //   });
  // }

  // getTotalUsersTransfers() {
  //   axios.get(`${consts.API_URL}/dashboard/total-customers-transfers`).then((resp) => {
  //     // console.log(resp)
  //     const labels = resp.data.map((data) => {
  //       return data.user_screen_name;
  //     });
  //     const series = [
  //       resp.data.map((data) => {
  //         return data.total;
  //       }),
  //     ];
  //     const totalUsersTransfers = { labels, series };
  //     this.setState({ totalUsersTransfers });
  //   });
  // }

  // getTotalPdvsTransfers() {
  //   axios.get(`${consts.API_URL}/dashboard/total-pdvs-transfers`).then((resp) => {
  //     console.log(resp);
  //     const labels = resp.data.map((data) => {
  //       return data.user_screen_name;
  //     });
  //     const series = [
  //       resp.data.map((data) => {
  //         return data.total;
  //       }),
  //     ];
  //     const totalPdvsTransfers = { labels, series };
  //     this.setState({ totalPdvsTransfers });
  //   });
  // }

  // getTotalReweeteds(){
  //   axios.get(`${consts.API_URL}/dashboard/total-retweeteds`)
  //   .then(resp => {
  //       const totalReweeteds = resp.data
  //       this.setState({ totalReweeteds })
  //   })
  // }
  //
  // getTotalUsersTalkingAbout(){
  //   axios.get(`${consts.API_URL}/dashboard/total-users-talking-about`)
  //   .then(resp => {
  //       const totalUsersTalkingAbout = resp.data
  //       this.setState({ totalUsersTalkingAbout })
  //   })
  // }
  //
  // getLocations(){
  //   axios.get(`${consts.API_URL}/dashboard/locations`)
  //   .then(resp => {
  //       const labels = resp.data.map(data => { return `${data.place_full_name}` })
  //       const series = resp.data.map(data => { return data.total })
  //       const locations = { labels, series }
  //       // console.log(locations)
  //       this.setState({ locations })
  //   })
  // }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <FaBoxes />
                </CardIcon>
                <p className={classes.cardCategory}>Virtuais</p>
                <h3 className={classes.cardTitle}>{this.state.account_balance_virtuals}</h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <FaBoxes />
                </CardIcon>
                <p className={classes.cardCategory}>Físicas</p>
                <h3 className={classes.cardTitle}>{this.state.account_balance_physical}</h3>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.banners.length > 0 ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                interval={5000}
                showStatus={false}
                showArrows={false}
              >
                {this.state.banners.map((item) => {
                  return (
                    <Card key={item.id} product className={classes.cardHover}>
                      <CardHeader image className={classes.cardHeaderHover}>
                        <a href={item.link} target="_blank">
                          <img src={item.image} alt="..." />
                        </a>
                      </CardHeader>
                    </Card>
                  );
                })}
              </Carousel>
            </GridItem>
          </GridContainer>
        ) : null}
      </div>
    );
  }
}

export default withStyles(chartsStyle)(Charts);
