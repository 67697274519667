import React from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink } from "react-router-dom";

import { login, recovery } from "./authActions";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import InstitutionIcon from "@material-ui/icons/Home";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import If from "../../common/operators/If";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      showPassword: true,
      model: {
        username: "",
        password: "",
      },
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  updateField(event) {
    const model = { ...this.state.model };
    model[event.target.name] = event.target.value;
    this.setState({ model });
  }

  onSubmit() {
    this.state.showPassword
      ? this.props.login(this.state.model)
      : this.props.recovery(this.state.model);
  }

  changeForm() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    const { classes, handleSubmit } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form
              onSubmit={handleSubmit((v) => {
                this.onSubmit();
                this.setState({ showPassword: true });
              })}
            >
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="primary"
                >
                  <h4 className={classes.cardTitle}>
                    {this.state.showPassword ? "Login" : "Recuperar senha"}
                  </h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      name: "username",
                      onChange: (e) => this.updateField(e),
                    }}
                  />
                  <If test={this.state.showPassword}>
                    <CustomInput
                      labelText="Senha"
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                          </InputAdornment>
                        ),
                        type: "password",
                        name: "password",
                        onChange: (e) => this.updateField(e),
                      }}
                    />
                  </If>
                  <span>
                    <a href="#this" onClick={() => this.changeForm()}>
                      {this.state.showPassword ? "Esqueci minha senha" : "Logar-se"}
                    </a>
                    .
                  </span>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button type="submit" color="primary" simple size="lg" block>
                    {this.state.showPassword ? "Entrar" : "Recuperar"}
                  </Button>
                </CardFooter>
                <CardFooter className={classes.justifyContentCenter}>
                  <span
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <NavLink
                      style={{
                        width: "100%",
                        color: "#000",
                      }}
                      to={"/register"}
                    >
                      Cadastrar-se
                    </NavLink>
                  </span>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

LoginPage = reduxForm({ form: "authForm" })(LoginPage);
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login,
      recovery,
    },
    dispatch
  );
const render = withStyles(loginPageStyle)(LoginPage);
export default connect(
  null,
  mapDispatchToProps
)(render);
