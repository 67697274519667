import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import Step1 from "./Steps/Step1.jsx";
import Step2 from "./Steps/Step2.jsx";

import consts from "../../consts";

class WizardView extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    const user = JSON.parse(localStorage.getItem(consts.UserKey));
    this.state = {
      user,
      brasal: props.match.path.indexOf("brasal") !== -1 ? true : false,
      showNotification: false,
      configNotification: {
        message: "Erro inesperado.",
        color: "danger",
      },
      parentState: null,
      finished: false,
    };
  }

  showNotification(message, color) {
    this.setState({
      configNotification: {
        message,
        color,
      },
      showNotification: true,
    });
    setTimeout(
      function() {
        this.hideNotification();
      }.bind(this),
      6000
    );
  }

  hideNotification() {
    this.setState({
      showNotification: false,
    });
  }

  transfer(qrcode) {
    // console.log("qrcode: ", qrcode);
    const that = this;
    const password =
      that.state.parentState.allStates &&
      that.state.parentState.allStates.length > 0 &&
      that.state.parentState.allStates[1].confirm &&
      that.state.parentState.allStates[1].confirm.pass !== ""
        ? that.state.parentState.allStates[1].confirm.pass
        : "";
    // console.log("password: ", password);

    if (password.length !== 4) {
      this.showNotification("Senha inválida.", "danger");
      return false;
    }

    const transfer = {
      from: this.state.user.account,
      to: qrcode.to,
      amount_virtual: qrcode.amount_virtual ? qrcode.amount_virtual : 0,
      amount_physical: qrcode.amount_physical ? qrcode.amount_physical : 0,
      amount_gift: qrcode.amount_gift ? qrcode.amount_gift : 0,
      password,
    };
    // console.log("transfer: ", transfer);

    const url =
      qrcode.brasal === 0
        ? `${consts.API_URL}/transfers/pdv/customer`
        : `${consts.API_URL}/transfers/pdv/brasal`;
    // console.log("url: ", url);

    axios
      .post(url, transfer)
      .then((resp) => {
        // console.log(resp);
        const message = "Transferência realizada com sucesso.";
        that.showNotification(message, "success");
        setTimeout(
          function() {
            that.setState({
              finished: true,
            });
          }.bind(this),
          3000
        );
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        const message =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Código inválido";
        that.showNotification(message, "danger");
      });
  }

  getQrCode(code) {
    axios
      .get(`${consts.API_URL}/codes/${code}`)
      .then((resp) => {
        // console.log(resp);
        this.transfer(JSON.parse(resp.data.value));
        // this.showNotification("teste", "success");
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        const message =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Código inválido";
        this.showNotification(message, "danger");
      });
  }

  finishButtonClick(state) {
    // console.log("finishButtonClick");
    // console.log(state);
    // console.log(state.allStates[0].code);
    // console.log(this.state);
    const that = this;

    that.setState(
      {
        parentState: state,
      },
      () => {
        setTimeout(() => {
          const code =
            state.allStates &&
            state.allStates.length > 0 &&
            state.allStates[0].code &&
            state.allStates[0].code.code !== ""
              ? state.allStates[0].code.code
              : "";
          // console.log("code: ", code);
          if (code === "") {
            return that.showNotification("Código inválido", "danger");
          }
          // console.log(`${consts.API_URL}/codes/${code}`);
          that.getQrCode(code);
        }, 50);
      }
    );
  }

  render() {
    if (this.state.finished) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <GridContainer justify="center">
        <Snackbar
          place="tr"
          color={this.state.configNotification.color}
          icon={AddAlert}
          message={this.state.configNotification.message}
          open={this.state.showNotification}
          closeNotification={() => this.hideNotification()}
          close
        />
        <GridItem xs={12} sm={8}>
          <Wizard
            validate
            steps={[
              // { stepName: "About", stepComponent: Step1, stepId: "about" },
              {
                stepName: "Código",
                stepComponent: Step1,
                stepId: "code",
                brasal: this.state.brasal,
              },
              {
                stepName: "Confirmação",
                stepComponent: Step2,
                stepId: "confirm",
                brasal: this.state.brasal,
              },
            ]}
            title={
              this.state.brasal
                ? "Devolver vasilhames para o Vendedor"
                : "Receber vasilhames do Consumidor"
            }
            subtitle=""
            nextButtonText="Confirmar"
            previousButtonText="Voltar"
            finishButtonText="Concluir"
            finishButtonClick={this.finishButtonClick.bind(this)}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default WizardView;
